import Button from "../../components/Button";
import Logo from "../../assets/imgs/logo.png";
import ImgHome1 from "../../assets/svgs/Home1.svg";
import ImgHome2 from "../../assets/svgs/Home2.svg";
import ImgHome3 from "../../assets/svgs/Home3.svg";
import ImgHome4 from "../../assets/svgs/Home4.svg";
import ImgHome5 from "../../assets/svgs/Home5.svg";
import './Home.style.scss';

export default function Home() {
  return (
    <>
      <section className="bg-purple description-section">
        <div className="description-section-infos">
          <img className="icon" src={Logo} alt="Brainybits" />
          <h1 className="company-title">Brainybits</h1>
          <p className="description">A melhor solução para sua empresa!</p>
          <div>
            <Button text="Serviços" />
          </div>
        </div>

        <img width="100%" height="100%" src={ImgHome1} alt="Brainybits" />
      </section>

      <section className="about bg-gray">
        <div style={{ textAlign: 'center' }}>
          <h1>Serviços</h1>
        </div>
        <div className="divisor">
          <div>
            <img className="service-image" src={ImgHome2} alt="Brainybits" />
            <h1>Criação de Landings inteligentes</h1>
            <p>Dê uma descrição detalhada do serviço fornecido aqui.</p>
          </div>

          <div>
            <img className="service-image" src={ImgHome3} alt="Brainybits" />
            <h1>Automação de serviços</h1>
            <p>Dê uma descrição detalhada do serviço fornecido aqui.</p>
          </div>

          <div>
            <img className="service-image" src={ImgHome5} alt="Brainybits" />
            <h1>Integração de serviços</h1>
            <p>Dê uma descrição detalhada do serviço fornecido aqui.</p>
          </div>
        </div>

        <p>
          Se quiser saber mais sobre os nossos serviços clique em{" "}
          <a href="">saber mais</a>{" "}
        </p>
        <Button variant="2" text="Saber mais" />
      </section>

      <section className="bg-purple">
        <div style={{ textAlign: 'center' }}>
          <h1>Inove conosco</h1>
        </div>
        <p>
          Automatize e simplifique as operações de negócios com soluções fáceis
          e seguras
        </p>
        <Button text="Fale conosco" />

        <img width="100%" height="100%" src={ImgHome4} alt="Brainybits" />
      </section>

      <footer className="bg-black">
        © 2024 Brainybits. Todos os direitos reservados.
      </footer>
    </>
  );
}
